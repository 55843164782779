<template>

  <b-container class="p-0" fluid="lg">
    <div class="d-flex">
      <b-button
        class="ml-auto"
        size="sm"
        variant="primary"
        @click="$router.go(-1)"
      >
        <b-icon-chevron-left />
        Back
      </b-button>
    </div>
    <h3>Captables</h3>
    <p>
      Create and Manager Your Captables
      <span class="text-danger">{{ error }}</span>
    </p>
    <div class="d-flex">
      <b-button
        variant="primary"
        class="ml-auto"
        size="sm"
        @click="captableForm = !captableForm"
      >
        <b-icon-plus />
        Create Captable
      </b-button>
    </div>
    <br />
    <b-row>
      <b-col cols="12" sm="12" class="mb-3" v-for="t in captables" :key="t._id">
        <b-card class="h-100" no-body>
          <b-card-header class="d-flex justify-content-between py-1 bg-gray">
            <small class="text-muted">
              <b-icon-people />
              {{ t.investors.length }}
            </small>

            <small class="text-muted">
              <span v-for="tag in t.tags" :key="tag" class="ml-1">
                <b-icon-tags />
                {{ tag }}
              </span>
            </small>
          </b-card-header>
          <b-card-body>
            <b-card-title class="text-uppercase">
              <strong>
              {{ t.memo.name }}
              </strong>
            </b-card-title>
            <b-card-text>{{ t.memo.description }}</b-card-text>
            <hr>
            <Updates :captable_id="t._id"></Updates>

          </b-card-body>
          <b-card-body class="d-flex">
            <b-button
              :to="{ name: 'Captable Detail', params: { id: t._id } }"
              variant="outline-secondary"
              size="sm"
            >
              <b-icon-unlock scale="0.8" />
              MANAGE
            </b-button>
   
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="captableForm"
      centered
      button-size="sm"
      @ok="addCaptable"
      @cancel="clearCaptableForm"
      @close="clearCaptableForm"
    >
      <template #modal-title>New Captable</template>
      <b-form>
        <b-form-group label="entity name">
          <b-input v-model="captableFormData.memo.name" required></b-input>
        </b-form-group>
        <b-form-group label="description">
          <b-textarea v-model="captableFormData.memo.description"></b-textarea>
        </b-form-group>
        <b-form-group label="tags">
          <b-form-tags v-model="captableFormData.tags"></b-form-tags>
        </b-form-group>
      </b-form>
      <template #modal-fooer="{cancel, ok}">
        <b-button @click="cancel">Cancel</b-button>
        <b-button @click="ok">Submit</b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
const axios = require('axios')
import Updates from '../../components/Updates'

import { mapGetters } from 'vuex'
export default {
  components: {Updates},
  data: function () {
    return {
      error: '',
      captables: [],
      captableForm: false,
      captableFormData: {
        ui: 'captable',
        memo: {
          name: '',
          description: '',
          address: '',
        },
        tags: [],
      },
    }
  },
  computed: {
    ...mapGetters('user', {
      isLoggedIn: 'getUserStatus',
      user: 'getUser',
    }),
  },
  methods: {
    addCaptable: function () {
      this.$store.isLoading = true
      console.log('add captable')
      axios
        .post('/.netlify/functions/captable/', this.captableFormData)
        .then((res) => {
          console.log(res.data)
          this.captables.push(res.data)
        })
        .catch((err) => {
          this.error = err
        })
        .finally(() => {
          this.$store.isLoading = false
        })
    },
    clearCaptableForm: function () {
      console.log('clear region form')
      this.captableForm = false
      this.captableFormData = {
        ui: 'captable',
        memo: {
          name: '',
          description: '',
          address: '',
        },
        tags: [],
      }
    },
  },
  created: async function () {
    this.$store.isLoading = true
    axios
      .get('/.netlify/functions/captable')
      .then((res) => {
        this.captables = res.data
      })
      .catch((err) => {
        this.error = err
      })
      .finally(() => {
        this.$store.isLoading = false
      })
  },
  watch: {},
}
</script>
