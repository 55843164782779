<template>
    <b-container class="p-0" fluid="lg">
      <h3>
        <b-avatar
          variant="warning"
          :text="this.captables.length ? this.captables.length : '0'"
          class="mr-1 align-baseline"
        ></b-avatar>CAPTABLE
      </h3>
      <p>Review your investments in any captable managed by us.</p>
      <div v-show="error" class="text-danger"> {{error}}</div>
      <hr>
      <b-row>
        <b-col cols="12" sm="12" class="my-3" v-for="t in captables" :key="t._id">
          <b-card no-body class="bg-white border-white h-100">
            <div class="d-flex pt-1">
              <small class="text-muted">
                <b-icon-people />
                {{ t.investors.length }}
              </small>
              <small class="text-muted">
                <span v-for="tag in t.tags" :key="tag" class="ml-1">
                  <b-icon-tags />
                  {{ tag }}
                </span>
              </small>
            </div>
            <h3 class="text-uppercase">
              <strong>{{ t.memo.name }}</strong>
            </h3>
            <p>{{ t.memo.description }}</p>
            <br />
            <Updates :captable_id="t._id"></Updates>
            <br />
            <div class="d-flex">
              <b-button
                :to="{ name: 'Investment Detail', params: { id: t._id } }"
                variant="outline-secondary"
                size="sm"
              >
                <b-icon-unlock scale="0.8" />DETAILS
              </b-button>
            </div>
            <hr />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
const axios = require("axios");
import Updates from "../../components/Updates";
import { mapGetters } from "vuex";
export default {
  components: { Updates },
  data: function() {
    return {
      isLoading: false,
      error: '',
      captables: []
    };
  },
  computed: {
    ...mapGetters("user", {
      isLoggedIn: "getUserStatus",
      user: "getUser"
    })
  },
  methods: {},
  created: async function() {
    this.$store.isLoading = true;
    try{
    let res = await axios
      .get("/.netlify/functions/investment")
    this.captables=res.data
    this.$store.isLoading = false;
    }
    catch(err){
      this.error=err
    }
  },
  watch: {}
};
</script>
