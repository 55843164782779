<template>
    <b-row>
      <b-col v-for="u in updates" :key="u" cols="12" sm="4" class="mb-3">
        <b-card no-body class="bg-warning border-gray h-100">
          <img v-if="u.imgURL" :src="u.imgURL" class="img" />
          <b-card-header class="bg-warning border-warning">
            <strong>{{ u.title }}</strong>
            <br />
            <small class="text-lowercase text-dark">
              <b-icon-calendar-date />
              {{ u.updatedAt.slice(0, 10) }}
              <b-icon-envelope class="ml-2" />
              {{ u.author.owner.email }}
            </small>
            <div>
              <b-badge
                variant="dark"
                v-for="t in u.tags"
                :key="t"
                class="mr-1 text-capitalize"
              >
                {{ t }}
              </b-badge>
            </div>
          </b-card-header>
          <b-card-body>
            <p>{{ u.body }}</p>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
</template>

<script>
const axios = require('axios')
export default {
  props: ['captable_id'],
  data() {
    return {
      updates: [],
    }
  },
  methods: {},
  created: async function () {
    this.$store.isLoading = true
    let res = await axios.get(`/.netlify/functions/update/?captable=${this.captable_id}`)
    this.updates = res.data.slice().reverse().slice(0,3)
    this.$store.isLoading = false
}
}
</script>

<style scoped>
img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
</style>
